import React from 'react';
import PropTypes from 'prop-types';

import Nav from './nav';
import Header from './header';
import Footer from './footer';
import MobileNav from './mobile-nav';

import './layout.less';
import 'antd/dist/antd.less';

const Layout = ({ images, url, children }) => (
	<>
		<Nav />
		<Header />
		<main>{children}</main>
		<Footer />
		<MobileNav />
	</>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
