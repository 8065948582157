import React from 'react';
import { graphql, Link, StaticQuery } from "gatsby"
import { Card, Icon } from "antd"
import Img from 'gatsby-image';

const Footer = () => (
	<StaticQuery
		query={graphql`
		      query SiteFooterQuery {
		        site {
		          siteMetadata {
		            title
		            address
		            postal
		            city
		            province
		            country
		            phone
		            fax
		            mobilePhone
		            email
		            footerNavigation {
		              name
		              path
		            }
		          }
		        }
		      }
		    `}
		render={data => (
			<footer>
				<div>
					<h4>Horaires:</h4>
					<p style={{ marginBottom: 0 }}>Lundi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
					<p style={{ marginBottom: 0 }}>Mardi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
					<p style={{ marginBottom: 0 }}>Mercredi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
					<p style={{ marginBottom: 0 }}>Jeudi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
					<p style={{ marginBottom: 0 }}>Vendredi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
					<p style={{ marginBottom: 0 }}>Samedi: <strong>10h00 - 14h00</strong> non-stop</p>
				</div>
				<div>
					<div className="contact-mobile contact-tablet">
						<h4>Informations de contact:</h4>
						<p>Téléphone: {data.site.siteMetadata.phone}<br />Email: {data.site.siteMetadata.email}</p>
					</div>
					<div className="contact-tablet">
						<h4>Adresse:</h4>
						<p>{data.site.siteMetadata.address}<br />{data.site.siteMetadata.postal} {data.site.siteMetadata.city}<br />{data.site.siteMetadata.province}, {data.site.siteMetadata.country}</p>
					</div>
					<div className="contact-tablet">
						{data.site.siteMetadata.footerNavigation.map((item, key) => (
							<React.Fragment key={key}>
								<Link key={key} to={item.path}>
									{item.icon && <Icon type={item.icon} />}
									{item.name}
								</Link>
								{key < data.site.siteMetadata.footerNavigation.length - 1 && ` - `}
							</React.Fragment>
						))}
					</div>
					<div className="contact-tablet" style={{ marginTop: 10 }}>
						© {new Date().getFullYear()} {data.site.siteMetadata.title}
					</div>
				</div>
				<div>
					<h4 className="contact-desktop">Informations de contact:</h4>
					<p className="contact-desktop" style={{ marginBottom: 40 }}>Téléphone: {data.site.siteMetadata.phone}<br />Email: {data.site.siteMetadata.email}</p>
					<small>Site internet par <a href="https://tailoredweb.ch/" target="_blank" rel="noreferrer noopener">TailoredWeb</a></small>
				</div>
			</footer>
		)}
	/>
);

export default Footer;
