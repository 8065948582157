import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Header = () => (
	<StaticQuery
		query={graphql`
	      query {
	        site {
	          siteMetadata {
	            title
	            subtitle
	          }
	        }
	        logo: file(relativePath: { eq: "logo.png" }) {
	          childImageSharp {
	            fluid(maxWidth: 600) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
	          }
	        }
	      }
	    `}
		render={data => (
			<header>
				<Img
					fluid={data.logo.childImageSharp.fluid}
					alt="BlackWay Motorcycles - Spécialiste Harley-Davidson à Bernex, Genève"
					style={{ maxWidth: 600 }}
				/>
				<h1 style={{ margin: 0 }}>{data.site.siteMetadata.subtitle}</h1>
			</header>
		)}
	/>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
