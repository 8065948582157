import React from 'react';
import { Card, Col, Row } from 'antd';

import Layout from '../components/layout';
import ContactForm from '../components/ContactForm';
import SEO from "../components/seo"

const CustomBikes = () => (
	<Layout>
		<SEO
			title="Contact"
			description="Retrouvez Black Way Motorcycles au Route de Pré-Marais 22, 1233 Bernex, Genève, Suisse."
			lang="fr"
			url="/contact"
		/>

		<div className="box-container first">
			<div className="content-block">
				<div className="content-block-bg" />
				<h2>Contact</h2>

				<Row type="flex" justify="space-around" style={{ marginBottom: 60 }} gutter={16}>
					<Col xs={24} md={12} xl={8} xxl={6}>
						<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
							<h3>RETROUVEZ-NOUS</h3>
							<p><strong>BLACK WAY SA</strong></p>
							<p style={{ marginBottom: 0 }}><a href="https://www.google.com/maps/place/Route+de+Pr%C3%A9-Marais+22,+1233+Bernex/data=!4m2!3m1!1s0x478c7c9787ca4161:0x72eb22515cb1d476?sa=X&ved=2ahUKEwjV1YiO5MXiAhUGaVAKHZiqCTsQ8gEwAHoECAoQAQ" target="_blank" rel="noopener nofollow">Route de Pré-Marais 22</a></p>
							<p style={{ marginBottom: 0 }}><a href="https://www.google.com/maps/place/Route+de+Pr%C3%A9-Marais+22,+1233+Bernex/data=!4m2!3m1!1s0x478c7c9787ca4161:0x72eb22515cb1d476?sa=X&ved=2ahUKEwjV1YiO5MXiAhUGaVAKHZiqCTsQ8gEwAHoECAoQAQ" target="_blank" rel="noopener nofollow">1233 Bernex, Genève, Suisse</a></p>
						</Card>
					</Col>

					<Col xs={24} md={12} xl={8} xxl={6}>
						<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
							<h3>CONTACTEZ-NOUS</h3>
							<p><a href="tel:+41 22 757 70 21">+41 22 757 70 21</a></p>
							<p style={{ marginBottom: 0 }}><a href="mailto:info@black-way.com">info@black-way.com</a></p>
						</Card>
					</Col>

					<Col xs={24} md={12} xl={8} xxl={6}>
						<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
							<h3>HORAIRES</h3>
							<p style={{ marginBottom: 0 }}>Lundi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
							<p style={{ marginBottom: 0 }}>Mardi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
							<p style={{ marginBottom: 0 }}>Mercredi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
							<p style={{ marginBottom: 0 }}>Jeudi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
							<p style={{ marginBottom: 0 }}>Vendredi: <strong>8h30-12h</strong> et <strong>13h30-18h</strong></p>
							<p style={{ marginBottom: 0 }}>Samedi: <strong>10h00 - 14h00</strong> non-stop</p>
						</Card>
					</Col>
				</Row>

				<ContactForm />
			</div>
		</div>
	</Layout>
);

export default CustomBikes;
