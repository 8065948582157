import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Icon, Menu } from 'antd';

const Nav = () => (
	<StaticQuery
		query={graphql`
	      query {
	        site {
	          siteMetadata {
	            mainNavigation {
	              name
	              path
	              icon
	            }
	          }
	        }
	      }
	    `}
		render={data => (
			<nav>
				<Menu mode="horizontal">
					{data.site.siteMetadata.mainNavigation.map((item, key) => (
						<Menu.Item key={key}>
							<Link to={item.path} activeClassName="active">
								{item.icon && <Icon type={item.icon} />}
								{item.name}
							</Link>
						</Menu.Item>
					))}
					<Menu.Item key="facebook">
						<a href="https://www.facebook.com/BlackWayCustomBikes" target="_blank" rel="noopener nofollow"><Icon type="facebook" style={{ fontSize: 20 }} /></a>
					</Menu.Item>
					<Menu.Item key="instagram">
						<a href="https://www.instagram.com/blackwaymotorcycles/" target="_blank" rel="noopener nofollow"><Icon type="instagram" style={{ fontSize: 20 }} /></a>
					</Menu.Item>
				</Menu>
			</nav>
		)}
	/>
);

export default Nav;
