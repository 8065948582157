import React, { useState, useEffect } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Icon, Button, Drawer } from 'antd';

const MobileNav = () => {
	const [mobileNav, setMobileNav] = useState(false);
	const [moveDirection, setMoveDirection] = useState('down');

	useEffect(() => {
		function handleScroll(event) {
			if (event.pageY + window.innerHeight > document.getElementById('___gatsby').offsetHeight - 50)
				setMoveDirection('up');
			else
				setMoveDirection('down');
		}

		window.addEventListener('scroll', handleScroll, false);

		return function cleanup() {
			window.removeEventListener('scroll', handleScroll, false);
		};
	});

	const moveDown = event => {
		event.preventDefault();

		window.scrollTo({
			behavior: 'smooth',
			left: 0,
			top: moveDirection === 'down' ? window.pageYOffset + window.innerHeight / 10 * 8 : 0
		});
	};

	return (
		<StaticQuery
			query={graphql`
		      query SiteMobileNavQuery {
		        site {
		          siteMetadata {
		            phone
		            mainNavigation {
		              name
		              path
		              icon
		            }
		          }
		        }
		      }
		    `}
			render={data => (
				<>
					<div className="mobile-toolbar">
						<div>
							<a href={`tel:${data.site.siteMetadata.phone}`} style={{ fontSize: 40 }}><Icon type="phone" /></a>
						</div>
						<div>
							<Button type="primary" shape="circle" size="large" icon="more" onClick={() => setMobileNav(!mobileNav)} />
						</div>
						<div>
							<a href="#" style={{ fontSize: 40 }} onClick={event => moveDown(event)}><Icon type={moveDirection} /></a>
						</div>
					</div>

					<Drawer className="mobile-nav" placement="left" visible={mobileNav} height="auto" mask={false} closable={false} onClose={() => setMobileNav(false)}>
						<ul>
							{data.site.siteMetadata.mainNavigation.map((item, key) => (
								<li key={key}>
									<Link to={item.path} activeClassName="active">
										{item.icon && <Icon type={item.icon} />}
										<div>{item.name}</div>
									</Link>
								</li>
							))}
						</ul>

						<div className="mobile-nav-socials">
							<a href="https://www.facebook.com/BlackWayCustomBikes" target="_blank" rel="noopener nofollow"><Icon type="facebook" /></a>
							<a href="https://www.instagram.com/blackwaymotorcycles/" target="_blank" rel="noopener nofollow"><Icon type="instagram" /></a>
						</div>
					</Drawer>
				</>
			)}
		/>
	);
}

export default MobileNav;
